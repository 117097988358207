import React from 'react';
import {Box, Grid, TextField, Typography, Button, Autocomplete} from "@mui/material";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {getAllWellnessTopic, modifyWellnessTopic} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import { useHistory } from "react-router-dom";

const topicRequest = {};

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function ChangeWellnessTopic(props) {

    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [wellnessTopics, setWellnessTopics] = React.useState(null);
    const [wellnessTopic, setWellnessTopic] = React.useState(null);

    React.useEffect(() => {
        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();
    }, []);

    const initialValues = async () => {
        
        setLoading(true);
        const response = await getAllWellnessTopic(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get wellness topics",
                icon: "error",
                button: "OK",
            });
            return;
        }

        setWellnessTopics(response);
    }

    const topicChange = (e, value) => {
        if(!value){
            return;
        }

        setWellnessTopic(value);
        setWellnessTopicEditable(value.name);
        topicRequest.topicId = value.id;
        topicRequest.name = value.name;
    };

    const [wellnessTopicEditable, setWellnessTopicEditable] = React.useState("");

    const changeTopicText = (e) => {
        setWellnessTopicEditable(e.target.value);
        topicRequest.name = e.target.value;
    }

    const submitTopicChange = async () => {
        setLoading(true);
        const response = await modifyWellnessTopic(topicRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to modify wellness topic",
                icon: "error",
                button: "OK",
            });
            return;
        }

        swal({
            title: "Success",
            text: "Wellness topic modified successfully",
            icon: "success",
            button: "OK",
        }).then(() => {
            history.push({
                pathname: "/handle_profile",
            })
        });
        return;
    }


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={2}>
                        {wellnessTopics &&
                            <>
                            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Autocomplete
                                    sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                    options={wellnessTopics}
                                    getOptionLabel={(option) => option.name}
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"topic not found"}
                                    fullWidth
                                    onChange={topicChange}
                                    renderInput={(params) => (
                                        <TextField  {...params} label="Topic" variant="standard" />
                                    )}
                                />
                            </Grid>
                            {wellnessTopic && wellnessTopicEditable &&
                            <>
                                <Grid item xs={12} sm={12} md={12} sx={{marginTop: "1rem"}}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth={true}
                                        multiline
                                        rows={3}
                                        label={<Typography variant="body2">Topic</Typography>}
                                        value={wellnessTopicEditable}
                                        onChange={(e)=> changeTopicText(e)}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Button onClick={submitTopicChange} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                        Submit
                                    </Button>
                                </Grid>
                            </>
                            }
                            </>
                        }
                    </Grid>
                }
            </Box>
        </Box>
    )
}

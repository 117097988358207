import React from 'react';
import {Box, Grid, TextField, Typography, Autocomplete, Button, MenuItem} from "@mui/material";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {changePersonalInfo} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

const changePersonalInfoRequest={};

export default function ChangeUSerInfo(props) {

    const {loginUser} = props;

    const userInfo = props.location?.state?.user?.personalInfo;

    React.useEffect(() => {
        changePersonalInfoRequest.clientUsername = props.location?.state?.user?.personalInfo?.username;
        changePersonalInfoRequest.firstName = props.location?.state?.user?.personalInfo?.firstName;
        changePersonalInfoRequest.phone = props.location?.state?.user?.personalInfo?.phone;
    }, []);

    const { t } = useTranslation();

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [firstName, setFirstName] = React.useState(props.location?.state?.user?.personalInfo?.firstName);
    const [phone, setPhone] = React.useState(props.location?.state?.user?.personalInfo?.phone);

    const changeFirstName = (e) => {
        changePersonalInfoRequest.firstName = e.target.value;
        setFirstName(e.target.value);
    };

    const phoneChange = (e) => {
        changePersonalInfoRequest.phone = e.target.value;
        setPhone(e.target.value);
    };

    const submitChanges = async () => {

        setLoading(true);
        const response = await changePersonalInfo(changePersonalInfoRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to change user info",
                icon: "error",
                button: "OK",
            });
            return;
        };

        swal({
            title: "Success",
            text: "User info changed successfully",
            icon: "success",
            button: "OK",
        }).then(() => {
            history.push('/admin/manage_users');
        });
    };

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: {xs: "start", sm: "start"},
                width: "95%",
                height: "99%",
                // textAlign: "center",
                marginTop: {xs: "1.3rem", sm: "1rem"},
                }}
            >
                {/* {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : */}
                {loading ? loaderAnimation:
                    
                    <Grid container spacing={2} sx={{display: "flex", justifyContent: "center", width: "90%", marginTop: {xs: "22%", sm: "6%"}}}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                variant="standard"
                                fullWidth={true}
                                label={<Typography variant="body2">{t("PersonalInformation.name")}</Typography>}
                                value={firstName}
                                onChange={(e)=> changeFirstName(e)}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                variant="standard"
                                label={<Typography variant="body2">{t("PersonalInformation.phone")}</Typography>}
                                fullWidth
                                value={phone}
                                autoComplete="off"
                                onChange={(e) => phoneChange(e)}
                                // inputProps={{ maxLength: 10, minLength: 10}}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                            <Button onClick={submitChanges} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                    
                }
            </Box>
        </Box>
    );
}

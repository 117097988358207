import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { css } from "@emotion/react"
import swal from "sweetalert"
import { PuffLoader } from "react-spinners"
import Colors from "../../components/ui/Colors"
import { webauthnPKRpId } from "../../Api";
import {Checkbox, Box, Grid, Button, TextField, Typography, Dialog, DialogActions, DialogContent, 
  DialogContentText, DialogTitle, Link, IconButton, InputAdornment} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import makeStyles from '@mui/styles/makeStyles';
import {authRegister, getUxLanguage, createWebauthnCredentials} from "../../utilityFunctions/AuthUtil";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
// import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import privacyConsent from '../../Texts/PrivacyConsent';
import FormControlLabel from '@mui/material/FormControlLabel';
import {loaderAnimation} from "../../Api";
import { useTranslation } from 'react-i18next';


const fpPromise = FingerprintJS.load();
// const fpPromise = FingerprintJS.load({token: 'nDQ31LvAqr3zFsWM8omJ'});

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const useStyles = makeStyles((theme) => ({
  
  dialogStyle:{
    whiteSpace: "pre-line"
  },
  
  linkStyle:{
    cursor: "pointer",
    textAlign: "center",
    color: "blue"
  },
}));

let secondFactor;

const restictedDomains = /romebook|pk2s|ponotaxi|pibwifi/i;
const restrictedCharacters = /[*ñ´|\":<>[\]{}`\\()';@& $áéíóúàèìòù]/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const restrictedPhoneCharacters = /[ \-+()a-zA-Z]/;

let deviceFingerprint;

let privacyConsentChecked = false;

const userParams ={
  username: "",
  email: "",
  password: "",
  account: "",
  totpEnabled: "",
  deviceFingerprint: "",
  privacyConsentChecked: false
}

export default function Register(props) {
  const { updateRegisterUser, updateNavBarEnabled, userdata } = props
  
  let history = useHistory()

  const { t, i18n } = useTranslation();

  const classes = useStyles();

  const steps = [
    {
      key: "username",
      name: t("Register.email"),
      value: "",
    },
    {
      key: "password",
      name: t("Register.password"),
      value: "",
    }
  ];

  const PASSWORDMESSAGE = t("Register.pwdHelper");

  const [userInfo, setUserInfo] = useState({
    username: "", email: "", password: ""
  })
  const [stepNumber, setStepNumber] = useState(0) 
  const [inputText, setInputText] = useState("")
  const [color, setColor] = useState("")
  const [strengthMessage, setStrengthMessage] = useState("")
  const [strengthLevel, setStrengthLevel] = useState(0);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchUxLang();
    
    updateNavBarEnabled(false);
    
    privacyConsentChecked = false;
    deviceFingerPrint(); 
  }, []);

  const deviceFingerPrint = async () =>{
    const fp = await fpPromise
    const result = await fp.get()

    // This is the visitor identifier:
    deviceFingerprint = result.visitorId
    console.log(deviceFingerprint) //DEBUG PRINTING

  }

  useEffect(() => {
    setInputText("");
  }, [stepNumber])

  const fetchUxLang = async() => {
    const langResponse = await getUxLanguage();

    if(!langResponse){
      return;
    }

    i18n.changeLanguage(langResponse.language);
  };

  const textChange = (e) => {
    
    setInputText(e.target.value)
    if(steps[stepNumber].key === 'password'){
      checkPassword(e.target.value);
    }
   
    setUserInfo({
      ...userInfo,
      [steps[stepNumber].key]: e.target.value
    })
  }

  const checkPassword = (password) => {
    //Regular Expressions.
    const regex = new Array();
    regex.push("[A-Z]"); //Uppercase Alphabet.
    regex.push("[a-z]"); //Lowercase Alphabet.
    regex.push("[0-9]"); //Digit.
    regex.push("[$@$!%*#?&]"); //Special Character.

    let level = 0;

    //Validate for each Regular Expression.
    for (let i = 0; i < regex.length; i++) {
        if (new RegExp(regex[i]).test(password)) {
          level++;
        }
    }

    //Validate for length of Password.
    if (level > 2 && password.length > 8) {
      level++;
    }

    if (password.length > 15) {
      level++;
    }
    
    switch (level) {
        
        case 1:
          setStrengthMessage(`${PASSWORDMESSAGE}`);
          setColor("red");
        break;
        case 2:
          setStrengthMessage(`${PASSWORDMESSAGE}`);
          setColor("darkgrey");
        break;
        case 3:
        case 4:
          setStrengthMessage(`${PASSWORDMESSAGE}`);
          setColor("darkorange");
        break;
        case 5:
          setStrengthMessage("Good");
          setColor("green");
        break;
        case 6:
          setStrengthMessage("Strong");
          setColor("darkgreen");
        break;
        case 0:
        default:
          setStrengthMessage("");
          setColor("");
          setStrengthLevel(0);
        
    }
    setStrengthLevel(level);
  };

  const handleNextClick = () => {
    
    if (!inputText) return

    if(steps[stepNumber].key === 'username'){
      if(!privacyConsentChecked){
        swal({
          title: t("Register.requiredInfo"),
          text: t("Register.missingPrivacyConsent"),
          icon: "info",
          button: "Ok",
        });
        return;
      };
    }
    if(steps[stepNumber].key === 'password'){
      if(strengthLevel < 5){
        setColor("red");
        setStrengthMessage(t("Register.pwdHelper"));
        return;
      }
    }
    if(steps[stepNumber].key === 'username' && restictedDomains.test(inputText)){
      swal({
        title: t("Register.restrictedEmailTitle"),
        text: t("Register.restrictedEmail"),
        icon: "error",
        buttons: "OK"
      }).then(()=>{
        setInputText("");
      });
      return;
    }

    if(steps[stepNumber].key === 'username' && !emailRegex.test(inputText)){
      swal({
        title: t("Register.invalidEmailTitle"),
        text: t("Register.invalidEmail"),
        icon: "error",
        buttons: "OK"
      }).then(()=>{
        setInputText("");
      });
      return;
    }

    if (stepNumber < steps.length - 1) {
      setStepNumber(stepNumber + 1);
    }
    else{
      submit();
    }
  }
  
  const submit = async () => {
    
    updateRegisterUser(userInfo);
    
    userParams.username = userInfo.username;
    userParams.email = userInfo.username;
    userParams.password = userInfo.password;
    userParams.totpEnabled = secondFactor;
    userParams.deviceFingerprint = deviceFingerprint;
    userParams.privacyConsentChecked= privacyConsentChecked;

    //Disable the navbar to hide it while the personal information is completed
    updateNavBarEnabled(false);

    setLoading(true);
    const response = await authRegister(userParams);
    setLoading(false);
    console.log(response);
    
    if(response.status === 200){

      swal({
        title: t("Register.newUserTitle"),
        icon: "success",
        button: "OK"
      })

      // secondFactorRegister(response.response);
    }

    else if(response.status === 201){

      newWebAuthnCredentials(response.response);

      // succesfullRegister(response.response);
      
    }
    
    else if(response.status === 428){
      swal({
        title: t("Register.confirmationRequired"),
        text: t("Register.authConditionEmail"),
        icon: "success",
        button: t("Register.emailConfirm")
      })
      .then(()=>{
        submit(); 
      });
    }
     
    else if(response.status === 409){
      swal({
        title: t("Register.duplicatedUsername"),
        // text: duplicatedUsername,
        icon: "error",
        button: "Ok"
      }).then(()=>{
        history.push({
          pathname: "/"
        });  
      }); 
    }

    else if(response.status === 417){
      swal({
        title: t("Register.duplicatedEmail"),
        // text: duplicatedEmail,
        icon: "error",
        button: "Ok"
      }).then(()=>{
        history.push({
          pathname: "/"
        });  
      }); 
    }

    else {
      swal({
        title: t("Register.registerError"),
        // text: errTransaction,
        icon: "error",
        button: "Entiendo"
      }).then(()=>{
        history.push({
          pathname: "/"
        });  
      }); 
    }
  };

  const newWebAuthnCredentials = async (userCredentials) => {

    console.log(userCredentials);

    const isBiometricAvailable = await checkWebAuthnSupport();

    if(!isBiometricAvailable){
      console.log("Biometric not available");
      succesfullRegister(userCredentials);
      return;
    };

    const hashedUsername = await sha256(userCredentials.username);

    const createWebAuthnOptions = {
      publicKey:{
        rp:{id: webauthnPKRpId , name: "Wupo"},
        user:{
          id: hashedUsername,
          name: userCredentials.username,
          displayName: userCredentials.username,
        },
        challenge: generateRandomChallenge(32),
        pubKeyCredParams: [
          {type: "public-key", alg: -7},
          { type: "public-key", alg: -257 } // -257 represents RS256
        ],
        authenticatorSelection: {
          authenticatorAttachment: "platform",
          userVerification: "required" // Requires user verification during credential creation
        }
      },
      allowCredentials: [
        {
          id: "credentialsIdentifierOne", // Identifier for the platform authenticator
          type: "public-key",
          transports: ["internal"] // Specify "internal" transport for platform authenticators
        },
        // Add other credentials as needed
      ]
    };

    console.log(createWebAuthnOptions);
    
    try{
      setLoading(true);
      const webAuthnResponse = await navigator.credentials.create(createWebAuthnOptions);

      if(!webAuthnResponse){
        setLoading(false);
        console.log("No response from webauthn");
        
        succesfullRegister(userCredentials);
        return;
      };

      console.log(webAuthnResponse);

      // Extract public key
      const publicKeyArrayBuffer = webAuthnResponse.response.getPublicKey();
      // Convert the public key to base64 string
      const publicKeyBase64 = arrayBufferToBase64Auth(publicKeyArrayBuffer);


      // Extract the authenticator data
      const authenticatorDataArrayBuffer = webAuthnResponse.response.getAuthenticatorData();
      // Convert the authenticator data to base64 string
      const authenticatorDataBase64 = arrayBufferToBase64Auth(authenticatorDataArrayBuffer);

      

      //Challenge validation
      const clientDataJSON = new TextDecoder().decode(webAuthnResponse.response.clientDataJSON);
      const clientData = JSON.parse(clientDataJSON);
      const receivedChallengeBytes = base64urlToUint8Array(clientData.challenge);


      if(!isEqualUint8Arrays(receivedChallengeBytes, createWebAuthnOptions.publicKey.challenge)){
        console.log("Challenge validation failed");
        succesfullRegister(userCredentials);
        return;
      }

      const credentialsRequest = {};
      credentialsRequest.username = userCredentials.username;
      credentialsRequest.credentialId = webAuthnResponse.id;
      credentialsRequest.publicKey = publicKeyBase64;
      credentialsRequest.authenticatorData = authenticatorDataBase64;

      setLoading(true);
      const response = await createWebauthnCredentials(credentialsRequest, userCredentials.accessToken);
      

      if(!response){
        swal({
          title: t("Register.biometricError"),
          icon: "error",
          button: "Ok",
        }).then(() => {
          succesfullRegister(userCredentials);
          return;
        });
      }

      swal({
        title: t("Register.biometricSuccessfull"),
        icon: "success",
        button: "Ok",
      }).then(() => {
        succesfullRegister(userCredentials);
        return;
      });

      console.log(response);
    }catch(error){
      setLoading(false);
      succesfullRegister(userCredentials);
      console.log(error);
      // handleNextClick();
    }

  };

  const checkWebAuthnSupport = async () => {
    if (!window.PublicKeyCredential) {
      console.log("WebAuthn not supported");
      return null;
    }
  
    try {
      const isBiometricAvailable = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
      if (!isBiometricAvailable) {
        console.log("WebAuthn biometric capacity not available");
        return null;
      }

      return true;
  
      // Continue with WebAuthn biometric registration or login
    } catch (error) {
      console.error("Error checking WebAuthn support:", error);
      return null;
    }
  };

  const sha256 = async (message) => {
    // Encode the message as UTF-8
    const msgBuffer = new TextEncoder().encode(message);
    // Calculate the hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    // Convert the hash buffer to a Uint8Array
    return new Uint8Array(hashBuffer);
  };

  const isEqualUint8Arrays = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
  }

  const base64urlToUint8Array = (base64urlString) => {
    const base64String = base64urlString.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64String);
    const buffer = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      buffer[i] = rawData.charCodeAt(i);
    }
    return buffer;
  };

  // Function to convert ArrayBuffer to base64
  const arrayBufferToBase64Auth = (arrayBuffer) => {
    const binary = new Uint8Array(arrayBuffer);
    const binaryString = String.fromCharCode.apply(null, binary);
    return btoa(binaryString);
  }

  const generateRandomChallenge = (length) => {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return array;
}

  const succesfullRegister = (body) => {

      const loginInfo = {
        accountId: body.username,
        ilpBearerToken: body.ilpBearerToken,
        totpToken: body.totpToken,
        jwtToken: body.accessToken,
        profileCompleted: body.profileCompleted,
        roles: body.roles  
      }

      // console.log(loginInfo);
      userdata(loginInfo);

      updateNavBarEnabled(false); //Disable NavBar

      console.log(userParams)
      history.push({pathname: "/onboarding/personalinformation",
                  state:{
                    loginInfo: loginInfo,
                    loginParams: userParams,
                  }
      });
      return;  
      
  };


  //Disclaimer and privacy consent handling
  const [disclaimer, setDisclaimer] = React.useState(false);
  function handleDisclaimer(){
      setDisclaimer(true);
  }

  const handleClose = () => {
      setDisclaimer(false);
  };

  const handleChange = (e) => {
    privacyConsentChecked = e.target.checked;
  };

  const goToLogin = () => {
    history.push("/login");
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
      <Box
          sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: {xs: "start", sm: "start"},
              width: "95%",
              height: "99%",
              // textAlign: "center",
              marginTop: {xs: "1.3rem", sm: "1rem"},
          }}
      >
        {/* {loading ? <PuffLoader size={200} color={Colors.secondary} css={cssLoader}/>: */}
        {loading ? loaderAnimation :
        <>
        <Grid container sx={{ position: "absolute", top: 0, left: 0}}>
          <Grid item xs={12}>
            <Box 
                component="img"
                sx={{
                  width: {xs:"80%", sm:"30%", md: "20%"},
                  height: {xs:"auto", sm:"auto", md: "auto"},
                }}
                alt="Logo"
                src="/images/logo-mpk.png"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{fontWeight: "bold"}}>{t("Register.slogan")}</Typography>
          </Grid>
        </Grid>
        <Grid container sx={{position: "absolute", bottom: 30}}>
          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Typography variant="body2" sx={{color: Colors.primary, fontWeight: "bold"}}>{t("Register.footerText")}</Typography>
          </Grid>
        </Grid>

        <Grid container sx={{display: "flex", justifyContent: "center", height: {xs:"80%", sm: "80%", md: "85%"}, width: "100%", marginTop: {xs: "4rem", sm: "2rem", md: "1rem"}}}>
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "end", marginTop: "0.5rem"}}>
              <Button onClick={goToLogin} component="label" sx={{backgroundColor: "none", color: Colors.primary, fontSize: "1rem", fontWeight: "bold"}}>
                {t("Register.alreadyRegistered")}
              </Button>
            </Grid>
            {stepNumber === 0 &&
              <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", textAlign: "center", alignItems: "end"}}>
                <Typography variant="body2" sx={{color: Colors.primary}}>{t("Register.emailExplanation")}</Typography>
              </Grid>
            }
            {stepNumber === 1 &&
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", textAlign: "center", marginTop: stepNumber === 0 ? "0rem" : "1.5rem"}}>
              <Typography variant="h4">{steps[stepNumber].name}</Typography>
            </Grid>
            }
          { stepNumber === 0 ?
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "0.1rem"}}>
              <TextField
                sx={{width: {xs: "90%", sm: "90%", md: "30%"}}}
                variant="standard"
                type="email"
                label={t("Register.email")}
                fullWidth
                value={inputText}
                autoComplete="given-email"
                onChange={textChange}
                // inputProps={{ maxLength: 10, minLength: 10}}
              />
            </Grid>
            : stepNumber === "x" ?
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center",  marginTop: "0.1rem"}}>
              <TextField
                sx={{width: {xs: "90%", sm: "90%", md: "30%"}}}
                variant="standard"
                id="email2"
                type="email"
                name="email2"
                label={t("Register.email")}
                fullWidth
                value={inputText}
                autoComplete="given-email"
                onChange={textChange}
              />
            </Grid>
            :
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center",  marginTop: "2.5rem"}}>
              <TextField
                sx={{width: {xs: "90%", sm: "90%", md: "30%"}}}
                variant="standard"
                id="password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                label={t("Register.password")}
                fullWidth
                value={inputText}
                onChange={textChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handlePasswordVisibility} edge="end">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          }
          { color && strengthMessage &&
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", marginTop: "1rem"}}>
              <em style={{color: color, whiteSpace: "pre-line", fontSize:"0.8rem", maxWidth: "300px"}}>{strengthMessage}</em>
            </Grid>
          }
          {stepNumber === 0 &&
            <>
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "end",}}>
              <FormControlLabel
                  required
                  control={<Checkbox color="secondary" name="privacyConsent" value="yes" />}
                  label={t("Register.acceptPrivaceConsent")}
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center", alignItems: "start",}}>
              <Link onClick={handleDisclaimer} className={classes.linkStyle}>
                {t("Register.privacyConsent")}
              </Link>
            </Grid>
            </>
          }
          <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
              <Button onClick={handleNextClick} variant="contained" size="big" sx={{backgroundColor: Colors.primary, marginTop: "1.5rem", height: "3rem", width: {xs: "50%", sm: "30%", md: "20%"}, borderRadius: "100px 100px 100px 100px",}}>
                {t("Register.continueButton")}
              </Button>
          </Grid>
        </Grid>
        <Dialog open={disclaimer} onClose={handleDisclaimer}>
          <DialogTitle>{t("Register.privacyConsent")}</DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.dialogStyle}>
          
            {privacyConsent}

            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
                Ok
            </Button>
          </DialogActions>
        </Dialog>
        </>
        }
      </Box>
    </Box>
  )
}
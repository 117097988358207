import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import NumberFormat from "react-number-format";
import { useTranslation } from 'react-i18next';



const creditRiskData={
    studyLevel: "",
    housingType: "",
    scoioeconomicStratum: "",
    maritalStatus: "",
    dependents: "",
    jobType: "",
    companyName: "",
    monthlyIncome: "",
    otherIncome: "",
    totalExpenses: "",
    totalAssets: "",
    totalLiabilities: "",
}

export default function CreditRiskInformation(props) {

    const{dataForParent} = props;

    const { t } = useTranslation();

    const studyLevels = [
        {
        type: "P",
        label: t("IDInformation.studyLevels.primarySchool")
        },
        {
        type: "S",
        label: t("IDInformation.studyLevels.secondarySchool")
        },
        {
        type: "T",
        label: t("IDInformation.studyLevels.technologist")
        },
        {
        type: "U",
        label: t("IDInformation.studyLevels.college")
        },
        {
        type: "E",
        label: t("IDInformation.studyLevels.specialization")
        },
        {
        type: "M",
        label: t("IDInformation.studyLevels.master")
        },
        {
        type: "D",
        label: t("IDInformation.studyLevels.phd")
        },
        {
        type: "N",
        label: t("IDInformation.studyLevels.none")
        },
        {
        type: "O",
        label: t("IDInformation.studyLevels.other")
        },
    ];

    const residenceTypes =[
    {
        type: "O",
        label: t("IDInformation.residenceTypes.otherResidence")
    },
    {
        type: "A",
        label: t("IDInformation.residenceTypes.renting")
    },
    {
        type: "P",
        label: t("IDInformation.residenceTypes.owner")
    },
    {
        type: "F",
        label: t("IDInformation.residenceTypes.family")
    },

    ];

    const maritialStatusTypes =[
    {
        type: "R",
        label: t("IDInformation.maritialStatusTypes.religious")
    },
    {
        type: "V",
        label: t("IDInformation.maritialStatusTypes.widow")
    },
    {
        type: "P",
        label: t("IDInformation.maritialStatusTypes.separated")
    },
    {
        type: "D",
        label: t("IDInformation.maritialStatusTypes.divorced")
    },
    {
        type: "U",
        label: t("IDInformation.maritialStatusTypes.cohabitation")
    },
    {
        type: "S",
        label: t("IDInformation.maritialStatusTypes.single")
    },
    {
        type: "C",
        label: t("IDInformation.maritialStatusTypes.married")
    },
    {
        type: "O",
        label: t("IDInformation.maritialStatusTypes.other")
    },
    ];

    const employmentTypes =[
    {
        type: "P",
        label: t("IDInformation.employmentTypes.partnerEmployee")
    },
    {
        type: "E",
        label: t("IDInformation.employmentTypes.employee")
    },
    {
        type: "A",
        label: t("IDInformation.employmentTypes.homemaker")
    },
    {
        type: "S",
        label: t("IDInformation.employmentTypes.partner")
    },
    {
        type: "I",
        label: t("IDInformation.employmentTypes.selfEmployed")
    },
    {
        type: "J",
        label: t("IDInformation.employmentTypes.retired")
    },
    {
        type: "O",
        label: t("IDInformation.employmentTypes.other")
    },
    ];

    const[scoioeconomicStratum, setScoioeconomicStratum]=React.useState("");
    const[dependents, setDependents]=React.useState("");
    const[companyName, setCompanyName]=React.useState("");
    const[monthlyIncome, setMonthlyIncome]=React.useState("");
    const[otherIncome, setOtherIncome]=React.useState("");
    const[totalExpenses, setTotalExpenses]=React.useState("");
    const[totalAssets, setTotalAssets]=React.useState("");
    const[totalLiabilities, setTotalLiabilities]=React.useState("");

    const stratumChange = (e) => {
        setScoioeconomicStratum(e.floatValue);
        creditRiskData.scoioeconomicStratum=e.floatValue;
        dataForParent(e, "scoioeconomicStratum");
    };

    const dependentsChange = (e) => {
        setDependents(e.floatValue);
        creditRiskData.dependents=e.floatValue;
        dataForParent(e, "dependents");
    };

    const companyNameChange = (e) => {
        setCompanyName(e.target.value);
        creditRiskData.companyName=e.target.value;
        dataForParent(e);
    };

    const monthlyIncomeChange = (e) => {
        setMonthlyIncome(e.floatValue);
        creditRiskData.monthlyIncome=e.floatValue;
        dataForParent(e, "monthlyIncome");
        
    };

    const otherIncomeChange = (e) => {
        setOtherIncome(e.floatValue);
        creditRiskData.otherIncome=e.floatValue;
        dataForParent(e, "otherIncome");
    };

    const totalExpensesChange = (e) => {
        setTotalExpenses(e.floatValue);
        creditRiskData.totalExpenses=e.floatValue;
        dataForParent(e, "totalExpenses");
    };

    const totalAssetsChange = (e) => {
        setTotalAssets(e.floatValue);
        creditRiskData.totalAssets=e.floatValue;
        dataForParent(e, "totalAssets");
    };

    const totalLiabilitiesChange = (e) => {
        setTotalLiabilities(e.floatValue);
        creditRiskData.totalLiabilities=e.floatValue;
        dataForParent(e, "totalLiabilities");
    };
    

    const[studyLevel, setStudyLevel]=React.useState("");
    const[residenceType, setResidenceType]=React.useState("");
    const[maritialStatus, setMaritialStatus]=React.useState("");
    const[employmentType, setEmploymentType]=React.useState("");

    const studyLevelChange = (e) => {
        setStudyLevel(e.target.value);
        creditRiskData.studyLevel=e.target.value;
        dataForParent(e);
    };

    const residenceTypeChange = (e) => {
        setResidenceType(e.target.value);
        creditRiskData.housingType=e.target.value;
        dataForParent(e);
    };

    const maritialStatusChange = (e) => {
        setMaritialStatus(e.target.value);
        creditRiskData.maritalStatus=e.target.value;
        dataForParent(e);
    };

    const employmentTypeChange = (e) => {
        setEmploymentType(e.target.value);
        creditRiskData.employmentType=e.target.value;
        dataForParent(e);
    };

    

    return (
        <Box
            sx={{
            display: "flex",
            width: "100%",
            // height: "80%",
            justifyContent: "center",
            alignContent:"center",
            // marginLeft: "1.5rem",
            }}
        >
            <Grid container spacing={3} sx={{ justifyContent: "center", width: "90%"}}>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <TextField
                        sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                        variant="standard"
                        fullWidth={true}
                        id="studyLevel"
                        name="studyLevel"
                        select
                        label={<Typography variant="body2">{t("IDInformation.studyLevel")}</Typography>}
                        value={studyLevel}
                        onChange={(e)=>studyLevelChange(e)}
                        autoComplete="off"
                    >
                        {studyLevels.map((typeEl) => (
                            <MenuItem key={typeEl.type} value={typeEl.type}>
                                {typeEl.label}
                            </MenuItem>
                        ))}
                    </TextField> 
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <TextField
                        sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                        variant="standard"
                        fullWidth={true}
                        id="residenceType"
                        name="residenceType"
                        select
                        label={<Typography variant="body2">{t("IDInformation.residenceType")}</Typography>}
                        value={residenceType}
                        onChange={(e)=>residenceTypeChange(e)}
                        autoComplete="off"
                    >
                        {residenceTypes.map((typeEl) => (
                            <MenuItem key={typeEl.type} value={typeEl.type}>
                                {typeEl.label}
                            </MenuItem>
                        ))}
                    </TextField> 
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "95%", height: "50%"}}
                        name="scoioeconomicStratum"
                        customInput={TextField}
                        // prefix={'$ '}
                        type="scoioeconomicStratum"
                        label={<Typography variant="body2">{t("IDInformation.scoioeconomicStratum")}</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={scoioeconomicStratum}
                        onValueChange={(values) => {
                            stratumChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <TextField
                        sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                        variant="standard"
                        fullWidth={true}
                        id="maritialStatus"
                        name="maritialStatus"
                        select
                        label={<Typography variant="body2">{t("IDInformation.maritalStatus")}</Typography>}
                        value={maritialStatus}
                        onChange={(e)=>maritialStatusChange(e)}
                        autoComplete="off"
                    >
                        {maritialStatusTypes.map((typeEl) => (
                            <MenuItem key={typeEl.type} value={typeEl.type}>
                                {typeEl.label}
                            </MenuItem>
                        ))}
                    </TextField> 
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "95%", height: "50%"}}
                        name="dependents"
                        customInput={TextField}
                        // prefix={'$ '}
                        type="dependents"
                        label={<Typography variant="body2">{t("IDInformation.dependents")}</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={dependents}
                        onValueChange={(values) => {
                            dependentsChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <TextField
                        sx={{textAlign: "start", width: {xs: "90%", sm: "70%", md: "50%"}}}
                        variant="standard"
                        fullWidth={true}
                        id="employmentType"
                        name="employmentType"
                        select
                        label={<Typography variant="body2">{t("IDInformation.employmentType")}</Typography>}
                        value={employmentType}
                        onChange={(e)=>employmentTypeChange(e)}
                        autoComplete="off"
                    >
                        {employmentTypes.map((typeEl) => (
                            <MenuItem key={typeEl.type} value={typeEl.type}>
                                {typeEl.label}
                            </MenuItem>
                        ))}
                    </TextField> 
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField
                        variant="standard"
                        value={companyName}
                        id="companyName"
                        name="companyName"
                        label={t("IDInformation.companyName")}
                        fullWidth
                        onChange={companyNameChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "95%", height: "50%"}}
                        name="monthlyIncome"
                        customInput={TextField}
                        prefix={'$ '}
                        type="monthlyIncome"
                        label={<Typography variant="body2">{t("IDInformation.monthlyIncome")}</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={monthlyIncome}
                        onValueChange={(values) => {
                            monthlyIncomeChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "95%", height: "50%"}}
                        name="otherIncome"
                        customInput={TextField}
                        prefix={'$ '}
                        type="otherIncome"
                        label={<Typography variant="body2">{t("IDInformation.otherIncome")}</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={otherIncome}
                        onValueChange={(values) => {
                            otherIncomeChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "95%", height: "50%"}}
                        name="totalExpenses"
                        customInput={TextField}
                        prefix={'$ '}
                        type="totalExpenses"
                        label={<Typography variant="body2">{t("IDInformation.totalExpenses")}</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={totalExpenses}
                        onValueChange={(values) => {
                            totalExpensesChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "95%", height: "50%"}}
                        name="totalAssets"
                        customInput={TextField}
                        prefix={'$ '}
                        type="totalAssets"
                        label={<Typography variant="body2">{t("IDInformation.totalAssets")}</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={totalAssets}
                        onValueChange={(values) => {
                            totalAssetsChange(values);
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} sx={{display: "flex", justifyContent: "center"}}>
                    <NumberFormat
                        variant="standard"
                        style={{width: "95%", height: "50%"}}
                        name="totalLiabilities"
                        customInput={TextField}
                        prefix={'$ '}
                        type="totalLiabilities"
                        label={<Typography variant="body2">{t("IDInformation.totalLiabilities")}</Typography>}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        autoComplete="off"
                        value={totalLiabilities}
                        onValueChange={(values) => {
                            totalLiabilitiesChange(values);
                        }}
                    />
                </Grid>
            </Grid>
      </Box>
    )
}

import React from 'react';
import {Box, Grid, TextField, Typography, Button, List, ListItem, Avatar, CircularProgress} from "@mui/material";
import swal from "sweetalert";
import {manageQuestionsChatFlow, createQuestionsChatSession, updateChatSessionSystemPrompts} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { useAudioStream } from '../speechai/useAudioStream';
import { useTranslation } from 'react-i18next';
import TopicSummary from '../summaries/TopicSummary';
import { useHistory } from "react-router-dom";

const newSessionRequest={};
const completionsRequest={};

let updateChatSessionEnabled = true;

export default function LlmQuestionsChat(props) {

    const { loginUser } = props;
    const history = useHistory();
    const {t} = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [chatLoading, setChatLoading] = React.useState(false);

    const { startRecording, stopRecording, playReceivedAudio, stopPlayingAudio, isSourceActive, } = useAudioStream();
    const [isRecording, setIsRecording] = React.useState(false);

    const[userMessage, setUserMessage] = React.useState("");
    const[chatHistory, setChatHistory] = React.useState(null);
    const[newSession, setNewSession] = React.useState(null);

    React.useEffect(() => {
        const createSession = async() => {
            createNewSession();
        }
        createSession();

        return () => {
            
            const cleanup = async () => {
                console.log("Calling completed session api");
                if(updateChatSessionEnabled === true){
                    await updateChatSession(); // Closing the chat session
                }
            };
            // cleanup(); // Call the async cleanup function
            
        };
    }, []);

    const createNewSession = async() => {
        
        newSessionRequest.reference = props.location?.state?.reference || "form_system_initial";
        newSessionRequest.topicId = props.location?.state?.topic?.id || props.location?.state?.topicId || 122;

        setLoading(true);
        const response = await createQuestionsChatSession(newSessionRequest, loginUser.jwtToken);
        setLoading(false);

        if (!response) {
            updateChatSessionEnabled = false;
            swal({ title: "Error", text: "Failed to create new session", icon: "error", button: "OK" });
            return;
        }

        else if(response.chatSession.status === "completed") {
            updateChatSessionEnabled = false;
            // swal({ 
            //     title: "Completed", 
            //     text: t("LlmQuestionsChat.topicCompletedMessage"), 
            //     icon: "success", 
            //     button: "OK" }).then(() => {
            //         history.push({
            //             pathname: "/consumer/llm_choose",
            //             state:{
            //                 topicId: newSessionRequest.topicId,
            //                 selectedTopicIndex: props?.location?.state?.selectedTopicIndex
            //             }
            //         });
            // });
            history.push({
                pathname: "/consumer/llm_choose",
                state:{
                    topicId: newSessionRequest.topicId,
                    selectedTopicIndex: props?.location?.state?.selectedTopicIndex
                }
            });
            
            return
        }

        setNewSession(response.chatSession);
        completionsRequest.sessionId = response.chatSession.id;

        if(response.chatSession.chatSessionData && response.chatSession.chatSessionData.length > 0) {
            setChatHistory(response.chatSession);
        }
    
    };

    const userMessageChange = async(e) => {
        setUserMessage(e.target.value);
        completionsRequest.userMessage = e.target.value;
    };

    const getChatCopletion = async() => {

        setUserMessage("");
        
        completionsRequest.topicId = props.location?.state?.topic?.id || props.location?.state?.topicId || 122;
        completionsRequest.reference = props.location?.state?.reference || "form_system_initial";
        
        setChatLoading(true);
        const response = await manageQuestionsChatFlow(completionsRequest, loginUser.jwtToken);
        setChatLoading(false);

        cleanAudioMessage();

        if (!response) {
            swal({ title: "Error", text: "Failed to get chat completion", icon: "error", button: "OK" });
            return;
        }

        else if(response.chatSession.status === "completed") {

            swal({ 
                title: "Completed", 
                text: t("LlmQuestionsChat.topicCompletedMessage"), 
                icon: "success", 
                button: "OK" 
            }).then(() => {
                history.push({
                    pathname: "/consumer/llm_choose",
                    state:{
                        topicId: completionsRequest.topicId,
                        selectedTopicIndex: props?.location?.state?.selectedTopicIndex
                    }
                });
            });
            
            return
        }

        setChatHistory(response.chatSession);

        if(response.base64AudioData){
            playReceivedAudio(response.base64AudioData);
        }
    };

    const updateChatSession = async() => {

        const updateSessionRequest ={
            sessionId: completionsRequest.sessionId ,
            reference: "grace_system_final",
            userMessage: "Conversation completed",
        }

        setLoading(true);
        const response = await updateChatSessionSystemPrompts(updateSessionRequest, loginUser.jwtToken);
        setLoading(false);

        // if (!response) {
        //     swal({ title: "Error", text: "Failed to update chat session", icon: "error", button: "OK" });
        //     return;
        // }

        // setChatHistory(response);
    };

    //Trigger scroll when chatHistory changes
    const chatEndRef = React.useRef(null);

    const scrollToBottom = () => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    React.useEffect(() => {
        scrollToBottom();
    }, [chatHistory]);

    const handleStartRecording = async () => {
        setIsPlaying(false);
        setIsRecording(true);
        await startRecording();
    };
    
    const handleStopRecording = async () => {
        setIsPlaying(true);
        setIsRecording(false);
        const audioRequest = await stopRecording();

        console.log(audioRequest);

        completionsRequest.audioData = audioRequest.audioData;
        completionsRequest.sampleRate = audioRequest.sampleRate;
        completionsRequest.isSpeechRequest = true;


        
        getChatCopletion();
        
    };

    const cleanAudioMessage = () => {
        completionsRequest.audioData = null;
        completionsRequest.sampleRate = null;
        completionsRequest.isSpeechRequest = false;
    };

    const handleStopPlayingAudio = async () => {
        stopPlayingAudio();
        setIsPlaying(false);
    };

    const[isPlaying, setIsPlaying] = React.useState(false);

    React.useEffect(() => {
        // Update state whenever sourceRef.current changes
        console.log("isSourceActive: ", isSourceActive);
        if(isSourceActive === false){
            setIsPlaying(false);
        }
    }, [isSourceActive]);
    
    
    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: {xs: "90%", sm: "90%", md: "90%"}, height: "90%", textAlign: "center", marginTop: { xs: "1.3rem", sm: 0 }}}>
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={1} sx={{justifyContent: "center"}}>
                        <Grid item xs={12} sm={8} md={8} sx={{display: "flex", justifyContent: "center"}}>
                            <Grid container spacing={1} sx={{height: { xs: '80vh', sm: '80vh', md: '80vh'}, overflowY: 'auto', marginTop: {sx: "1rem", sm: "1rem", md: "3rem"}}}>
                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "start" }}>
                                    {/* <Avatar sx={{ bgcolor: Colors.primary, mr: "2%"}}>G</Avatar> */}
                                    <Avatar
                                        src="/images/grace-icon.png" // Direct relative path to the image in the public folder
                                        alt="Grace Icon"
                                        sx={{
                                            width: { xs: "40px", sm: "100px" }, 
                                            height: { xs: "40px", sm: "100px" },
                                        }}
                                    />
                                </Grid>
                                {chatHistory && chatHistory.chatSessionData &&
                                    <Grid item xs={12}>
                                        <List sx={{ width: "95%", marginTop: { xs: "3rem", sm: "2rem", md: "2rem" } }}>
                                        {chatHistory.chatSessionData
                                            .slice() // Create a shallow copy to avoid mutating the original array
                                            .sort((a, b) => new Date(b.startTime) - new Date(a.startTime)) // Sort by startTime (latest first)
                                            .map((data, index) => (
                                                <Grid key={data.id} container spacing={1}>
                                                    {data.userMessage &&
                                                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", textAlign: "end" }}>
                                                            <ListItem disablePadding sx={{ width: {xs: "78%", sm: "50%"}, marginBottom: "1.5rem", bgcolor: "#d0ebff", borderRadius: "16px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "0.75rem 1rem"}}>
                                                                {/* <Typography variant="body2" sx={{ fcolor: "black", whiteSpace: "pre-wrap" }}>{data.userMessage}</Typography> */}
                                                                <Typography variant="body2" sx={{ color: "black" }}>
                                                                    {data.userMessage.split(/\r?\n/).map((line, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {line}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    ))}
                                                                </Typography>
                                                            </ListItem>
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", textAlign: "start" }}>
                                                        <ListItem disablePadding sx={{ width: {xs: "78%", sm: "50%"}, marginBottom: "1.5rem", bgcolor: "grey.200", borderRadius: "16px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "0.75rem 1rem" }}>
                                                            {/* <Typography variant="body2" sx={{ color: "black", whiteSpace: "pre-wrap" }}>{data.assistantMessage}</Typography> */}
                                                            <Typography variant="body2" sx={{ color: "black" }}>
                                                                {data.assistantMessage.split(/\r?\n/).map((line, index) => (
                                                                    <React.Fragment key={index}>
                                                                        {line}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </Typography>
                                                        </ListItem>
                                                    </Grid>
                                                </Grid>
                                                
                                            ))}
                                            {/* Scroll anchor */}
                                            <div ref={chatEndRef} />
                                        </List>
                                    </Grid>
                                }
                                {chatLoading ? 
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "center"}}>
                                        <CircularProgress /> 
                                    </Grid>
                                :
                                <>
                                    <Grid item xs={8} sx={{display: "flex", alignItems: "center", justifyContent: "end", marginBottom: "2rem"}}>
                                        <TextField
                                            value={userMessage}
                                            onChange={(e) => userMessageChange(e)}
                                            placeholder="Type a message..."
                                            multiline
                                            minRows={1}
                                            maxRows={5} // Adjust to set maximum height
                                            fullWidth
                                            sx={{
                                                width: "70%",
                                            '& .MuiInputBase-root': {
                                                borderRadius: '16px', // Optional: Add rounded edges
                                            },
                                            }}
                                        />
                                    </Grid>
                                    {userMessage &&
                                    <Grid item xs={4} sx={{display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "2rem"}}>
                                        <Button
                                            variant="contained"
                                            sx={{backgroundColor: Colors.primary, color: Colors.white, borderRadius: '16px'}}
                                            onClick={getChatCopletion}
                                        >
                                            <PlayCircleFilledIcon style={{fontSize: "2rem"}}/>
                                        </Button>
                                    </Grid>
                                    }
                                    {!userMessage && isRecording === false && isPlaying===false &&
                                    <Grid item xs={4} sx={{display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "2rem"}}>
                                        <Button
                                            variant="contained"
                                            sx={{backgroundColor: `${Colors.primary} !important`, color: Colors.white, borderRadius: '16px'}}
                                            onClick={handleStartRecording}
                                        >
                                            <MicIcon style={{fontSize: "2rem"}}/>
                                        </Button>
                                    </Grid>
                                    }
                                    {!userMessage && isRecording === true &&
                                    <Grid item xs={4} sx={{display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "2rem"}}>
                                        <Button
                                            variant="contained"
                                            sx={{backgroundColor: `red !important`, color: Colors.white, borderRadius: '16px'}}
                                            onClick={handleStopRecording}
                                        >
                                            <MicIcon style={{fontSize: "2rem"}}/>
                                        </Button>
                                    </Grid>
                                    }
                                    {isPlaying===true &&
                                    <>
                                        <Grid item xs={1} sx={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "2rem"}}>
                                            <Button
                                                variant="contained"
                                                sx={{backgroundColor: `${Colors.primary} !important`, color: Colors.white, borderRadius: '16px'}}
                                                onClick={handleStartRecording}
                                            >
                                                <MicIcon style={{fontSize: "2rem"}}/>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={3} sx={{display: "flex", alignItems: "center", justifyContent: "start", marginBottom: "2rem"}}>
                                            <Button
                                                variant="contained"
                                                sx={{backgroundColor: `red !important`, color: Colors.white, borderRadius: '16px'}}
                                                onClick={handleStopPlayingAudio}
                                            >
                                                <StopIcon style={{fontSize: "2rem"}}/>
                                            </Button>
                                        </Grid>
                                    </>
                                    }
                                </>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={0} sm={4} md={4} sx={{display: {xs: "none", sm: "flex"}, justifyContent: "center", position: "sticky", top: "4rem", height: "calc(100vh - 4rem)"}}>
                            <TopicSummary 
                                    loginUser={loginUser}
                                    selectedTopicIndex={props?.location?.state?.selectedTopicIndex}
                            />
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    );
}

import React from 'react';
import {audioInputFileUpload} from '../../../utilityFunctions/WellnessUtil';

let audioChunks = [];

export default function SpeachToText(props) {

    const {loginUser} = props;


    const [mediaRecorder, setMediaRecorder] = React.useState(null);
    // const [audioChunks, setAudioChunks] = React.useState([]);
    const [isRecording, setIsRecording] = React.useState(false);

    const startRecording = async () => {

        try {
            
            // Request access to the microphone with specific audio settings
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    // sampleRate: 16000,      // Use the sample rate of the microphone
                    channelCount: 1,        // Mono audio (1 channel)
                    echoCancellation: true, // Enable echo cancellation
                    noiseSuppression: true, // Enable noise suppression
                },
            });
            
            const recorder = new MediaRecorder(stream);

            recorder.ondataavailable = (event) => {
                console.log("Data available");
                console.log(event.data);
                // setAudioChunks((prevChunks) => [...prevChunks, event.data]);
                audioChunks.push(event.data);
            };

            recorder.start();
            setMediaRecorder(recorder);
            setIsRecording(true);
        } catch (error) {
            console.error("Error accessing microphone:", error);
        }
    };


    const stopRecording = async () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);

            mediaRecorder.onstop = async () => {
                // Combine chunks into a single Blob (WAV file)
                console.log("Recording stopped");
                console.log(audioChunks);
                const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
                // setAudioChunks([]);
                audioChunks = [];

                // Upload the file to the server
                const formData = new FormData();
                formData.append("audioFile", audioBlob, "audio.wav");

                audioInputFileUpload(formData, loginUser.jwtToken);
            };
        }
    };

    return (
        <div>
            <button onClick={startRecording} disabled={isRecording}>
                Start Recording
            </button>
            <button onClick={stopRecording} disabled={!isRecording}>
                Stop Recording
            </button>
        </div>
    );
}

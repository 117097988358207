import React from 'react';
import {Box, Grid, TextField, Typography, Button, Autocomplete, List, ListItem} from "@mui/material";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import {getAllUsernames, getSingleUser, getAllAdvisorClientsInfo} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import { useHistory } from "react-router-dom";

const dateOptions = {
    // weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
};

export default function ChatSessionsList(props) {

    const {loginUser, retirementRole} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const[usernames, setUsernames] = React.useState(null);
    const[username, setUsername] = React.useState(null);
    const[wellnessUser, setWellnessUser] = React.useState(null);
    const[session, setSession] = React.useState(null);

    React.useEffect(() => {
        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();
    }, []);

    const initialValues = async () => {
        
        if(retirementRole && retirementRole === "advisor"){
            setLoading(true);
            const response = await getAllAdvisorClientsInfo(loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: "Failed to get users",
                    icon: "error",
                    button: "OK",
                });
                return;
            }
    
            setUsernames(response);
        }
        else{
            setLoading(true);
            const response = await getAllUsernames(loginUser.jwtToken);
            setLoading(false);

            if(!response){
                swal({
                    title: "Error",
                    text: "Failed to get users",
                    icon: "error",
                    button: "OK",
                });
                return;
            }
    
            setUsernames(response);
        }

    };

    const usernameChange = async (e, value) => {
        if(!value){
            return;
        }

        setUsername(value);

        getlClient(value);
        
    };

    const getlClient = async (value) => {
        setLoading(true);
        const response = await getSingleUser(value, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get user",
                icon: "error",
                button: "OK",
            });
            return;
        };
        
        setSession(null);
        setWellnessUser(response);
    };

    const sessionChange = (e, value) => {
        if(!value){
            return;
        }

        setSession(value);
    };

    const gotToSessionDetail = () => {

        if(!session.chatSessionData){
            swal({
                title: "Information",
                text: "No chat history found",
                icon: "info",
                button: "OK",
            });
            return;
        }

        console.log(session);
        history.push({
            pathname: "/admin/grace-sessions/detail",
            state: {chatHistory: session.chatSessionData}
        });
        return;
    }

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={1} sx={{justifyContent: "center"}}>
                        {usernames &&
                            <>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Autocomplete
                                    sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                    options={usernames}
                                    getOptionLabel={(option) => option.username}
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"username not found"}
                                    fullWidth
                                    onChange={usernameChange}
                                    renderInput={(params) => (
                                        <TextField  {...params} label="Username" variant="standard" />
                                    )}
                                />
                            </Grid>
                            {wellnessUser && wellnessUser.chatSessions &&
                                <>
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Autocomplete
                                        sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                        options={
                                            [...wellnessUser.chatSessions].sort(
                                            (a, b) => new Date(b.startTime) - new Date(a.startTime)
                                            )
                                        }
                                        getOptionLabel={(option) => `${new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(option.startTime))} - Sentiment: ${option.sentiment ? option.sentiment : "Pending"}`}
                                        clearOnEscape
                                        autoComplete = {true}
                                        noOptionsText = {"Session not found"}
                                        fullWidth
                                        onChange={sessionChange}
                                        renderInput={(params) => (
                                            <TextField  {...params} label="Client's sessions" variant="standard" />
                                        )}
                                    />
                                </Grid>
                                {session &&
                                    <>
                                    <List sx={{width: {xs: "100%", sm: "80%"}, marginTop: {xs: "3rem", sm: "2rem", md: "2rm"}}}>
                                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2" sx={{fontSize: "12px", color: "grey"}}>Session Summary</Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2">{session.sessionSummary}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2" sx={{fontSize: "12px", color: "grey"}}>Session Sentiment</Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2">{session.sentiment}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2" sx={{fontSize: "12px", color: "grey"}}>Sentiment Summary</Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2">{session.sentimentDetail}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2" sx={{fontSize: "12px", color: "grey"}}>Session Emotion</Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2">{"Coming soon..."}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem> 
                                        <ListItem disablePadding divider sx={{marginBottom: "1.5rem"}}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2" sx={{fontSize: "12px", color: "grey"}}>Emotion Summary</Typography>
                                                </Grid>   
                                                <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                    <Typography variant="body2">{"Coming Soon..."}</Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                        {!retirementRole &&
                                        <Grid item xs={12}>
                                            <Button onClick={gotToSessionDetail} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                                Detail
                                            </Button>
                                        </Grid>
                                        }
                                    </List>
                                    </>
                                }
                                </>
                            }
                            </>
                        }
                    </Grid>
                }
            </Box>
        </Box>
    )
}

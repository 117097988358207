import React from 'react';
import {Box, Grid, TextField, Typography, Button, Autocomplete} from "@mui/material";
import NumberFormat from "react-number-format";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import {getAllWellnessTopic, modifyWellnessAttribute} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import { useHistory } from "react-router-dom";

const attributeRequest = {};

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function ChangeWellnessAttribute(props) {

    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [wellnessTopics, setWellnessTopics] = React.useState(null);
    const [wellnessTopic, setWellnessTopic] = React.useState(null);
    const [wellnessQuestion, setWellnessQuestion] = React.useState("");
    const [questionGroup, setQuestionGroup] = React.useState("");

    React.useEffect(() => {
        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();
    }, []);

    const initialValues = async () => {
        
        setLoading(true);
        const response = await getAllWellnessTopic(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get wellness topics",
                icon: "error",
                button: "OK",
            });
            return;
        }

        setWellnessTopics(response);
    }

    const topicChange = (e, value) => {
        if(!value){
            return;
        }

        setWellnessTopic(value);
        
    };

    const questionChange = (e, value) => {
        if(!value){
            return;
        }

        setWellnessQuestion(value.id);
        setWellnessQuestionEditable(value.name);
        setQuestionGroup(value.questionGroup ? value.questionGroup : "");

        attributeRequest.attributeId = value.id;
        attributeRequest.name = value.name;
        attributeRequest.questionGroup = value.questionGroup ? value.questionGroup : "";

    };

    const [wellnessQuestionEditable, setWellnessQuestionEditable] = React.useState("");

    const changeQuestionText = (e) => {
        setWellnessQuestionEditable(e.target.value);
        attributeRequest.name = e.target.value;
    };


    const questionGroupChange = (values) => {
        setQuestionGroup(values.floatValue);
        attributeRequest.questionGroup = values.floatValue;
    };

    const submitQuestionChange = async () => {
        setLoading(true);
        const response = await modifyWellnessAttribute(attributeRequest, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to modify wellness attribute",
                icon: "error",
                button: "OK",
            });
            return;
        }

        swal({
            title: "Success",
            text: "Wellness attribute modified successfully",
            icon: "success",
            button: "OK",
        }).then(() => {
            history.push({
                pathname: "/handle_profile",
            })
        });
        return;

    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {/* {loading ? <PuffLoader size={100} color={Colors.secondary} css={cssLoader} /> : */}
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={2}>
                    {wellnessTopics &&
                    <>
                        <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                            <Autocomplete
                                sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                options={wellnessTopics}
                                getOptionLabel={(option) => option.name}
                                clearOnEscape
                                autoComplete = {true}
                                noOptionsText = {"topic not found"}
                                fullWidth
                                onChange={topicChange}
                                renderInput={(params) => (
                                    <TextField  {...params} label="Topic" variant="standard" />
                                )}
                            />
                        </Grid>
                        {wellnessTopic && wellnessTopic.wellnessAttributes &&
                            <>
                                <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Autocomplete
                                        sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                        options={wellnessTopic.wellnessAttributes}
                                        getOptionLabel={(option) => option.name}
                                        clearOnEscape
                                        autoComplete = {true}
                                        noOptionsText = {"Question not found"}
                                        fullWidth
                                        onChange={questionChange}
                                        renderInput={(params) => (
                                            <TextField  {...params} label="Questions" variant="standard" />
                                        )}
                                    />
                                </Grid>
                                {wellnessQuestion && wellnessQuestionEditable &&
                                <>
                                    <Grid item xs={12} sm={12} md={12} sx={{marginTop: "1rem"}}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth={true}
                                            multiline
                                            rows={3}
                                            label={<Typography variant="body2">Question</Typography>}
                                            value={wellnessQuestionEditable}
                                            onChange={(e)=> changeQuestionText(e)}
                                            autoComplete="off"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <NumberFormat
                                            variant="standard"
                                            style={{width: "40%", height: "50%"}}
                                            customInput={TextField}
                                            type="text"
                                            label={<Typography variant="body2">Group</Typography>}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            autoComplete="off"
                                            value={questionGroup}
                                            onValueChange={(values) => {
                                                questionGroupChange(values);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Button onClick={submitQuestionChange} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                            Submit
                                        </Button>
                                    </Grid>
                                </>
                                }
                            </>
                        }
                    </>
                    }
                    </Grid>
                }
            </Box>
        </Box>
    )
}

import React from 'react';
import {handleAudioStreaming} from '../../../utilityFunctions/WellnessUtil';

let audioContext; // Store the audio context globally
let stream;       // Store the media stream globally
let mediaStreamSource; // Store mediaStreamSource globally
let audioProcessorNode = null;  // Declare audioProcessorNode in a higher scope
let audioChunks = [];

export default function SpeechToTextStreaming(props) {

    const{loginUser, sessionId} = props;

    const [isRecording, setIsRecording] = React.useState(false);
    const audioContextRef = React.useRef(null);  // Reference to the AudioContext
    const sourceRef = React.useRef(null);        // Reference to the audio source node
    
    const startRecording = async () => {

        try{
            // Request access to the microphone with specific audio settings
            stream = await navigator.mediaDevices.getUserMedia({
                audio: {
                    // sampleRate: 16000,      // Use the sample rate of the microphone
                    channelCount: 1,        // Mono audio (1 channel)
                    echoCancellation: true, // Enable echo cancellation
                    noiseSuppression: true, // Enable noise suppression
                },
            });
        
            audioContext = new AudioContext();

            console.log(audioContext.sampleRate);
            
            // Check if the AudioWorkletNode is supported
            if (audioContext.audioWorklet) {
                // Load the audio worklet processor
                await audioContext.audioWorklet.addModule('/audio-processor.js');
                
                mediaStreamSource = audioContext.createMediaStreamSource(stream);
                audioProcessorNode = new AudioWorkletNode(audioContext, 'audio-processor');
                
                mediaStreamSource.connect(audioProcessorNode);
                audioProcessorNode.connect(audioContext.destination);
            
                // Listen for audio processing events from the processor
                audioProcessorNode.port.onmessage = (event) => {
                    const audioBuffer = event.data;  // Get the Int16Array buffer
                    audioChunks.push(audioBuffer);  // Accumulate the audio buffer in an array
                };

                setIsRecording(true);
            } else {
                console.error("AudioWorklet is not supported in this browser.");
            }
        }catch(err){
            console.error("Error accessing the microphone or initializing audio stream:", err);
        }
    };

    const stopRecording = async () => {
        setIsRecording(false);
        if (stream) {
            // Stop all tracks in the media stream
            stream.getTracks().forEach(track => track.stop());
            stream = null;  // Clear the stream reference
        }


        if (audioProcessorNode) {
            audioProcessorNode.disconnect();
            audioProcessorNode.port.onmessage = null; // Stop receiving messages
            audioProcessorNode = null; // Clear the audioProcessorNode reference
        }
    
    
        if (audioContext && audioContext.state !== 'closed') {
            // Disconnect the mediaStreamSource and close the audio context
            if (mediaStreamSource) {
                mediaStreamSource.disconnect();
                mediaStreamSource = null;  // Clear mediaStreamSource reference
            }
            audioContext.close().then(() => {
                console.log('AudioContext closed');
                audioContext = null;  // Clear the audioContext reference
            });
        };

        // Flatten the `audioChunks` array into a single buffer
        const flattenedBuffer = new Int16Array(audioChunks.reduce((acc, chunk) => acc + chunk.length, 0));
        let offset = 0;
        audioChunks.forEach(chunk => {
            flattenedBuffer.set(chunk, offset);
            offset += chunk.length;
        });

        const audioRequest = {
            sampleRate: audioContext.sampleRate,
            audioData: Array.from(flattenedBuffer) // Convert to an array for JSON serialization
        };

        if(sessionId){
            audioRequest.sessionId = sessionId;
        }

        const audioResponse = await handleAudioStreaming(audioRequest, loginUser.jwtToken);

        if(!audioResponse){
            console.error("Error processing the audio stream");
        }

        const textResponse = audioResponse.text;  // Get the text from the response
        console.log(`Received text: ${textResponse}`);

        playReceivedAudio(audioResponse.base64AudioData);

    };

    const playReceivedAudio = async (base64Audio) => {
        
        console.log(base64Audio)

        // Convert Base64 to Blob
        const audioBlob = new Blob([Uint8Array.from(atob(base64Audio), c => c.charCodeAt(0))], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);

        await enableAudioContext();
        
        console.log('Received audio data');
        const audioData = await audioBlob.arrayBuffer();
        const audioBuffer = await audioContextRef.current.decodeAudioData(audioData);

        // Stop the previous audio source if it exists
        if (sourceRef.current) {
            console.log('Stopping previous audio source');
            sourceRef.current.stop();
        }

        // Create a new audio buffer source
        console.log('Playing new audio source');
        const source = audioContextRef.current.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContextRef.current.destination);
        source.start(0);

        sourceRef.current = source; // Store the reference to the current
    };

    const enableAudioContext = async () => {
        console.log('Enabling audio context');
        if (!audioContextRef.current) {
            console.log('Creating new AudioContext');
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContextRef.current = audioContext;

            console.log(`Audio context state ${audioContext.state}`);
        }

        // Resume the AudioContext if it's in a suspended state
        if (audioContextRef.current.state === 'suspended') {
            await audioContextRef.current.resume();
            console.log('AudioContext resumed');
        }

    }
    
    return (
        <div>
        <button onClick={startRecording} disabled={isRecording}>
            Start Recording
        </button>
        <button onClick={stopRecording} disabled={!isRecording}>
            Stop Recording
        </button>
    </div>
    )
}

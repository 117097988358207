import React from 'react';
import {Box, Grid, Typography, Button, List, ListItem, ListItemButton, Avatar, Tooltip, Card} from "@mui/material";
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import {getTopicSummary} from '../../../utilityFunctions/WellnessUtil';
import swal from "sweetalert";
import { useHistory } from "react-router-dom";


export default function TopicSummary(props) {

    const summaryPendingText = "You’re actively exploring this topic and making progress toward deeper insights—keep going, your dedication is opening the door the way to meaningful growth!";

    const{loginUser, topicSummary, selectedTopicIndex} = props;
    const history = useHistory();

    const[loading, setLoading] = React.useState(false);
    const topicName = React.useRef("Financial Wellness");
    const selectedAvatarIndex = React.useRef(0);
    const[topicIndex, setTopicIndex] = React.useState(0);
    

    // const[topicSummary, setTopicSummary] = React.useState(_topicSummary || null);

    React.useEffect(() => {
        console.log(topicSummary);
        console.log(selectedTopicIndex);
        console.log(avatars[selectedTopicIndex].title);
    }, []);

    // const initialValues = async () => {
    //     const summary = props?.location?.state?.summary || null;
    //     setTopicSummary(summary);
    // };

    const getSummaryAndSteps = (item, index) => {
        topicName.current = item.title;
        selectedAvatarIndex.current = index;
        history.push({
            pathname: "/consumer/llm_choose",
            state: {
                topicId: item.topicId,
                selectedTopicIndex: index,
            }
        });
    };

    const avatars = [
        { icon: <TrackChangesIcon />, topicId: 122, title: "Financial Wellness" },
        { icon: <ExploreOutlinedIcon />, topicId: 124, title: "Purpose & Legacy" },
        { icon: <FavoriteBorderOutlinedIcon />, topicId: 120, title: "Health & Well-Being" },
        { icon: <GroupOutlinedIcon />, topicId: 119, title: "Relationships & Identity" },
        { icon: <LightModeOutlinedIcon />, topicId: 123, title: "Exploration & Leisure" },
        { icon: <TroubleshootOutlinedIcon />, topicId: null, title: "Emotions assessment" } // Example without action
    ];

    // const avatars = [
    //     { icon: <TrackChangesIcon />, topicId: 49370, title: "Financial Wellness" },
    //     { icon: <ExploreOutlinedIcon />, topicId: 49372, title: "Purpose & Legacy" },
    //     { icon: <FavoriteBorderOutlinedIcon />, topicId: 49371, title: "Health & Well-Being" },
    //     { icon: <GroupOutlinedIcon />, topicId: 49372, title: "Relationships & Identity" },
    //     { icon: <LightModeOutlinedIcon />, topicId: 49372, title: "Exploration & Leisure" },
    //     { icon: <TroubleshootOutlinedIcon />, topicId: null, title: "Emotions assessment" } // Example without action
    // ];

    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "95%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: "3rem"},
                }}
            >
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={2} sx={{height: "100%", overflow: "hidden" }}>
                        <Grid item xs={12} sx={{ width: "100%", position: "sticky", top: 0, zIndex: 10, paddingBottom: "0.5rem", paddingTop: "0.5rem", }}>
                            <Grid container justifyContent="center" spacing={2}>
                                {avatars.map((item, index) => (
                                    <Grid item key={index} xs={2} sx={{ textAlign: "center" }}>
                                        <Tooltip title={item.title} arrow>
                                            <Avatar
                                                onClick={() => item && getSummaryAndSteps(item, index)}
                                                sx={{
                                                    backgroundColor: selectedTopicIndex === index ? "#00008b" : Colors.primary, // Change background color for selected avatar
                                                    width: selectedTopicIndex === index ? "4rem" : "3.5rem", // Slightly increase size for the selected avatar
                                                    height: selectedTopicIndex === index ? "4rem" : "3.5rem",
                                                    cursor: "pointer",
                                                    boxShadow:
                                                    selectedTopicIndex === index
                                                            ? "0 4px 10px rgba(0,0,0,0.3)" // Add a stronger shadow for selected avatar
                                                            : "0 2px 5px rgba(0,0,0,0.2)",
                                                    transition: "all 0.2s ease-in-out", // Smooth transitions for scaling and other effects
                                                    transform: selectedTopicIndex === index ? "scale(1.1)" : "scale(1)", // Slight scaling effect
                                                    "&:hover": {
                                                        transform: "scale(1.15)", // A bit more scaling on hover
                                                    },
                                                }}
                                            >
                                                {item.icon}
                                            </Avatar>
                                        </Tooltip>
                                    </Grid>
                                ))}
                                {selectedTopicIndex !== null && selectedTopicIndex !== undefined &&
                                    <Grid item xs={12} sm={12} md={12} sx={{display: "flex", justifyContent: "center"}}>
                                        <Typography variant="h6" sx={{ color: "black", fontWeight: "bold" }}>
                                            {avatars[selectedTopicIndex].title}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ 
                                height: "calc(100% - 8.5rem)", // Adjust height to prevent cutting off
                                overflowY: "auto",
                                padding: "1rem",
                            }}
                        >
                            {topicSummary &&
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Card sx={{
                                        width: "100%",
                                        marginTop: "1rem",
                                        padding: "1.5rem",
                                        boxShadow: 3,
                                        borderRadius: "0.75rem",
                                        backgroundColor: "#f9f9f9",
                                    }}>
                                        <List sx={{width: "100%",}}>
                                            <ListItem disablePadding divider sx={{marginBottom: "0.3rem", marginTop: "0.3rem"}}>
                                                <Grid container sx={{display: "flex"}}>
                                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                        <Typography variant="body2" sx={{ color: Colors.primary, fontWeight: "bold" }}>
                                                            Summary:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                        <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify"}}>
                                                            {topicSummary.summary.split(/\r?\n/).map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "1.3rem"}}>
                                                <Grid container sx={{display: "flex"}}>
                                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "start"}}>
                                                        <Typography variant="body2" sx={{ color: Colors.primary, fontWeight: "bold" }}>
                                                            Action Steps:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                                        <Typography variant="body2" sx={{ color: "black", lineHeight: 1.6, textAlign: "justify" }}>
                                                            {topicSummary.actionSteps.split(/\r?\n/).map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Card>
                                </Grid>
                            }
                            {!topicSummary &&
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <Card sx={{ 
                                        width: "100%",
                                        marginTop: "1rem",
                                        padding: "1.5rem",
                                        boxShadow: 3,
                                        borderRadius: "0.75rem",
                                        backgroundColor: "#f9f9f9",
                                    }}>
                                        <Typography variant="body2" sx={{ color: "black" }}>
                                            {summaryPendingText.split(/\r?\n/).map((line, index) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    <br />
                                                </React.Fragment>
                                            ))}
                                        </Typography>
                                    </Card>
                                </Grid>

                            }
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}

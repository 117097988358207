import React from 'react';
import {Box, Grid, TextField, Typography, Button, Autocomplete, List, ListItem, Dialog, ListItemButton, Avatar} from "@mui/material";
import swal from "sweetalert";
import {getAllUsernames, getSingleUser, handleBlockUser, deleteWellnessUser} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import BlockIcon from '@mui/icons-material/Block';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useHistory } from "react-router-dom";

export default function ManageUsers(props) {

    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const[usernames, setUsernames] = React.useState(null);
    const[username, setUsername] = React.useState(null);
    const[wellnessUser, setWellnessUser] = React.useState(null);

    React.useEffect(() => {
        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();
    }, []);

    const initialValues = async () => {
        
        setLoading(true);
        const response = await getAllUsernames(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get users",
                icon: "error",
                button: "OK",
            });
            return;
        }

        setUsernames(response);

    };

    const usernameChange = async (e, value) => {
        if(!value){
            return;
        }

        setUsername(value);

        getlClient(value);
        
    };

    const getlClient = async (value) => {
        setLoading(true);
        const response = await getSingleUser(value, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get user",
                icon: "error",
                button: "OK",
            });
            return;
        };
        
        
        setWellnessUser(response);
    };

    React.useEffect(() => {
        if(wellnessUser){
            openActionBox();
        }else{
            closeActionBox();
        }
    }, [wellnessUser]);

    const[actionBox, setActionBox] = React.useState(false);

    const openActionBox = () => {
        setActionBox(true);
    };

    const closeActionBox = () => {
        setActionBox(false);
    };

    const blockUser = async () => {

        setLoading(true);
        const response = await handleBlockUser({username: username}, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to block user",
                icon: "error",
                button: "OK",
            });
            return;
        }

        setWellnessUser(response);

        swal({
            title: "Success",
            text: "User blocked",
            icon: "success",
            button: "OK",
        });

    };

    const goToChangeUser = () => {
        history.push({
            pathname: '/admin/change_user_info',
            state: {user: wellnessUser}
        });
    };

    const deleteUser = async () => {


            
        setLoading(true);
        const response = await deleteWellnessUser(username, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to delete user",
                icon: "error",
                button: "OK",
            });
            return;
        }

        swal({
            title: "Success",
            text: "User deleted",
            icon: "success",
            button: "OK",
        });

        initialValues();

    };


    return (
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", height: "100%"}}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "95%",
                    height: "90%",
                    textAlign: "center",
                    marginTop: {xs: "1.3rem", sm: 0},
                }}
            >
                {loading ? loaderAnimation :
                    <>
                    <Grid container direction="row" spacing={1} sx={{justifyContent: "center"}}>
                        {usernames &&
                            <>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Autocomplete
                                    sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                    options={usernames}
                                    getOptionLabel={(option) => option.username}
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"username not found"}
                                    fullWidth
                                    onChange={usernameChange}
                                    renderInput={(params) => (
                                        <TextField  {...params} label="Username" variant="standard" />
                                    )}
                                />
                            </Grid>
                            </>
                        }
                    </Grid>

                    <Dialog open={actionBox} onClose={closeActionBox}
                        sx={{
                            "& .MuiDialog-container": {
                                "& .MuiPaper-root": {
                                    width: {xs: "80%", sm: "40%", md: "30%"},
                                    padding: "1.5rem", // Add padding to the dialog
                                    borderRadius: "16px", // Softer corners
                                },
                            },
                        }}
                        >
                            <List sx={{width: "100%",}}>
                                <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "0.3rem"}}>
                                    <ListItemButton  onClick={blockUser}>
                                        <Grid container>
                                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <Avatar sx={{ backgroundColor: Colors.primary, width: "3rem", height: "3rem" }}>
                                                    <BlockIcon sx={{ fontSize: "1.5remrem", color: "white" }} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <Typography variant="h6" sx={{ml:{xs: "0.8rem", sm: "1rem"}}}>{"Block Or Unblock"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "0.3rem"}}>
                                    <ListItemButton  onClick={goToChangeUser}>
                                        <Grid container>
                                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <Avatar sx={{ backgroundColor: Colors.primary, width: "3rem", height: "3rem" }}>
                                                    <ChangeCircleIcon sx={{ fontSize: "1.5remrem", color: "white" }} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <Typography variant="h6" sx={{ml:{xs: "0.8rem", sm: "1rem"}}}>{"Change Info"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding sx={{marginBottom: "0.3rem", marginTop: "0.3rem"}}>
                                    <ListItemButton  onClick={deleteUser}>
                                        <Grid container>
                                            <Grid item xs={2} sm={2} md={2} sx={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                <Avatar sx={{ backgroundColor: Colors.primary, width: "3rem", height: "3rem" }}>
                                                    <HighlightOffIcon sx={{ fontSize: "1.5remrem", color: "white" }} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={9} sm={9} md={9} sx={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <Typography variant="h6" sx={{ml:{xs: "0.8rem", sm: "1rem"}}}>{"Delete User"}</Typography>
                                            </Grid>
                                        </Grid>
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Dialog>
                    </>
                    
                }
            </Box>
        </Box>
    )
}

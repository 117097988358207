import React from 'react';
import {Box, Grid, TextField, Typography, Button, Autocomplete } from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import swal from "sweetalert";
import {getAllUsernames, calculateAdvisorUserTopicScores} from '../../../utilityFunctions/WellnessUtil';
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import { useHistory } from "react-router-dom";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F1E9FE', '#FAF4FF', '#F7D3ED'];

let totalValue;

export default function AdminClientsDashboards(props) {

    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const[usernames, setUsernames] = React.useState(null);
    const[username, setUsername] = React.useState(null);
    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();
    }, []);

    const initialValues = async () => {
        
        setLoading(true);
        const response = await getAllUsernames(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get users",
                icon: "error",
                button: "OK",
            });
            return;
        }

        setUsernames(response);
    };

    const usernameChange = async (e, value) => {
        if(!value){
            return;
        }

        setUsername(value);

        calculateScore(value);
        
    };

    const calculateScore = async (value) => {
        setLoading(true);
        const response = await calculateAdvisorUserTopicScores(value, loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get wellness topics",
                icon: "error",
                button: "OK",
            });
            return;
        }
        
        totalValue = response.reduce((acc, entry) => acc + entry.value, 0);
        console.log(totalValue);

        setData(response);
        return response;
    }

    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "95%", height: "90%", textAlign: "center", marginTop: { xs: "1.3rem", sm: 0 } }}>
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={1}>
                        {usernames &&
                            <>
                            <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                <Autocomplete
                                    sx={{width: {xs: "90%", sm: "80%", md: "60%"}}}
                                    options={usernames}
                                    getOptionLabel={(option) => option.username}
                                    clearOnEscape
                                    autoComplete = {true}
                                    noOptionsText = {"username not found"}
                                    fullWidth
                                    onChange={usernameChange}
                                    renderInput={(params) => (
                                        <TextField  {...params} label="Username" variant="standard" />
                                    )}
                                />
                            </Grid>
                            </>
                        }
                        {username &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h4" sx={{ marginTop: "1rem", fontWeight: "blond" }}>{"MyLifePortfolio\u2122"}</Typography>
                            </Grid>
                            {data && data.length > 0 &&
                                <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                                    <ResponsiveContainer width="100%" height={400}>
                                        <PieChart>
                                            <Pie
                                            data={data}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={({ cx, cy, midAngle, innerRadius, outerRadius, name, value }) => {
                                                const RADIAN = Math.PI / 180;
                                                const radius = outerRadius + 20; // Move label 20px outside the outer edge
                                                const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                                const y = cy + radius * Math.sin(-midAngle * RADIAN);
                                                const percentage = (value * 100).toFixed(2);

                                                return (
                                                <g>
                                                    {/* Name Text */}
                                                    <text
                                                        x={x}
                                                        y={y - 10} // Adjust y position for stacking
                                                        fill="#333"
                                                        fontSize={11}
                                                        fontWeight="bold"
                                                        textAnchor={x > cx ? 'start' : 'end'}
                                                        dominantBaseline="central"
                                                    >
                                                        {name}
                                                    </text>
                                                    {/* Percentage Text */}
                                                    <text
                                                        x={x}
                                                        y={y + 11} // Adjust y position to display below the name
                                                        fill="#888"
                                                        fontSize={11}
                                                        textAnchor={x > cx ? 'start' : 'end'}
                                                        dominantBaseline="central"
                                                    >
                                                        {`${percentage}%`}
                                                    </text>
                                                </g>
                                                );
                                            }}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                            >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                            </Pie>

                                            {/* Custom Legend */}
                                            <Legend
                                                content={({ payload }) => (
                                                    <ul style={{ listStyle: 'none', padding: 0, margin: 0, fontSize: '14px', color: '#555'}}>
                                                    {payload.map((entry, index) => (
                                                        <li key={`item-${index}`} style={{ marginBottom: 10}}>
                                                            <span style={{ color: entry.color, fontWeight: 'bold' }}>●</span>
                                                            <span style={{ marginLeft: 8 }}>{entry.value}</span>
                                                        </li>
                                                    ))}
                                                    </ul>
                                                )}
                                            />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </Grid>
                            }
                        </>
                        }
                    </Grid>
                }
            </Box>
        </Box>
    )
}

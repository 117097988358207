import React from 'react';
import {Box, Grid, Typography, Button, List, ListItem, Avatar} from "@mui/material";
import swal from "sweetalert";
import Colors from "../../../components/ui/Colors";
import { loaderAnimation } from '../../../Api';
import { useHistory } from "react-router-dom";

export default function ChatSessionDetail(props) {

    const history = useHistory();
    const [loading, setLoading] = React.useState(false);

    const chatHistory = props.location?.state?.chatHistory;

    const goToSessionsList = () => {
        history.push("/admin/grace_sessions");
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: {xs: "90%", sm: "90%", md: "70%"}, height: "90%", textAlign: "center", marginTop: { xs: "1.3rem", sm: 0 }}}>
                {loading ? loaderAnimation :
                    <Grid container spacing={1} sx={{height: { xs: '80vh', sm: '80vh', md: '80vh'}, overflowY: 'auto', marginTop: {sx: "1rem", sm: "1rem", md: "3rem"}}}>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "start" }}>
                            {/* <Avatar sx={{ bgcolor: Colors.primary, mr: "2%"}}>G</Avatar> */}
                            <Avatar
                                src="/images/grace-icon.png" // Direct relative path to the image in the public folder
                                alt="Grace Icon"
                                sx={{
                                    width: { xs: "40px", sm: "100px" }, 
                                    height: { xs: "40px", sm: "100px" },
                                }}
                            />
                        </Grid>
                        {chatHistory && 
                            <Grid item xs={12}>
                                <List sx={{ width: "95%", marginTop: { xs: "3rem", sm: "2rem", md: "2rem" } }}>
                                    {chatHistory
                                        .slice() // Create a shallow copy to avoid mutating the original array
                                        .sort((a, b) => new Date(b.startTime) - new Date(a.startTime)) // Sort by startTime (latest first)
                                        .map((data, index) => (
                                        <Grid key={data.id} container spacing={1}>
                                            {data.userMessage &&
                                                <Grid item xs={12} sx={{ display: "flex", justifyContent: "end", textAlign: "end" }}>
                                                    <ListItem disablePadding sx={{ width: "50%", marginBottom: "1.5rem", bgcolor: "#d0ebff", borderRadius: "16px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "0.75rem 1rem"}}>
                                                        {/* <Typography variant="body2" sx={{ fcolor: "black" }}>{data.userMessage}</Typography> */}
                                                        <Typography variant="body2" sx={{ color: "black" }}>
                                                            {data.userMessage.split(/\r?\n/).map((line, index) => (
                                                                <React.Fragment key={index}>
                                                                    {line}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))}
                                                        </Typography>
                                                    </ListItem>
                                                </Grid>
                                            }
                                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "start", textAlign: "start" }}>
                                                <ListItem disablePadding sx={{ width: "50%", marginBottom: "1.5rem", bgcolor: "grey.200", borderRadius: "16px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", padding: "0.75rem 1rem" }}>
                                                    {/* <Typography variant="body2" sx={{ color: "black" }}>{data.assistantMessage}</Typography> */}
                                                    <Typography variant="body2" sx={{ color: "black" }}>
                                                        {data.assistantMessage.split(/\r?\n/).map((line, index) => (
                                                            <React.Fragment key={index}>
                                                                {line}
                                                                <br />
                                                            </React.Fragment>
                                                        ))}
                                                    </Typography>
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                        
                                    ))}
                                </List>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12}>
                            <Button onClick={goToSessionsList} variant="contained" sx={{backgroundColor: Colors.primary, marginTop: "2rem",}}>
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}

import React from 'react';
import {ChatSessionsList} from "../../pages/admin/index";


export default function ClientSessionsList(props) {

    const {loginUser} = props;

    return (
        <ChatSessionsList 
            retirementRole = "advisor"
            loginUser = {loginUser}
        />
    );
}

import React from 'react';
import { Box, Grid, Typography, List, ListItem, Checkbox, FormControlLabel, Button } from "@mui/material";
import swal from "sweetalert";
import { PuffLoader } from "react-spinners";
import { css } from "@emotion/react";
import Colors from "../../components/ui/Colors";
import {calculateUserTopicScores} from '../../utilityFunctions/WellnessUtil';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { loaderAnimation } from '../../Api';
import { useHistory } from "react-router-dom";

const cssLoader = css`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#F1E9FE', '#FAF4FF', '#F7D3ED'];

let totalValue;

export default function ConsumerPlanCalculation(props) {
    const {loginUser} = props;

    let history = useHistory();

    const [loading, setLoading] = React.useState(false);

    const [data, setData] = React.useState(null);

    React.useEffect(() => {
        const loadInitialValues = async () => {
            await initialValues();
        }
        loadInitialValues();
    }, []);

    const initialValues = async () => {
        setLoading(true);
        const response = await calculateUserTopicScores(loginUser.jwtToken);
        setLoading(false);

        if(!response){
            swal({
                title: "Error",
                text: "Failed to get wellness topics",
                icon: "error",
                button: "OK",
            });
            return;
        }
        
        totalValue = response.reduce((acc, entry) => acc + entry.value, 0);
        console.log(totalValue);

        setData(response);
        return response;
    }


    return (
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "95%", height: "90%", textAlign: "center", marginTop: { xs: "1.3rem", sm: 0 } }}>
                {loading ? loaderAnimation :
                    <Grid container direction="row" spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h4" sx={{ marginTop: "1rem", fontWeight: "blond" }}>{"MyLifePortfolio\u2122"}</Typography>
                    </Grid>
                    {data && data.length > 0 &&
                        <Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>
                            <ResponsiveContainer width="100%" height={400}>
                                <PieChart>
                                    <Pie
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={({ cx, cy, midAngle, innerRadius, outerRadius, name, value }) => {
                                        const RADIAN = Math.PI / 180;
                                        const radius = outerRadius + 20; // Move label 20px outside the outer edge
                                        const x = cx + radius * Math.cos(-midAngle * RADIAN);
                                        const y = cy + radius * Math.sin(-midAngle * RADIAN);
                                        // const percentage = (value * 100).toFixed(2);
                                        const percentage = Math.round(value * 100);

                                        return (
                                        <g>
                                            {/* Name Text */}
                                            <text
                                                x={x}
                                                y={y - 10} // Adjust y position for stacking
                                                fill="#333"
                                                fontSize={11}
                                                fontWeight="bold"
                                                textAnchor={x > cx ? 'start' : 'end'}
                                                dominantBaseline="central"
                                            >
                                                {name}
                                            </text>
                                            {/* Percentage Text */}
                                            <text
                                                x={x}
                                                y={y + 11} // Adjust y position to display below the name
                                                fill="#888"
                                                fontSize={11}
                                                textAnchor={x > cx ? 'start' : 'end'}
                                                dominantBaseline="central"
                                            >
                                                {`${percentage}%`}
                                            </text>
                                        </g>
                                        );
                                    }}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                    >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                    </Pie>

                                    {/* Custom Legend */}
                                    <Legend
                                        content={({ payload }) => (
                                            <ul style={{ listStyle: 'none', padding: 0, margin: 0, fontSize: '14px', color: '#555'}}>
                                            {payload.map((entry, index) => (
                                                <li key={`item-${index}`} style={{ marginBottom: 10}}>
                                                    <span style={{ color: entry.color, fontWeight: 'bold' }}>●</span>
                                                    <span style={{ marginLeft: 8 }}>{entry.value}</span>
                                                </li>
                                            ))}
                                            </ul>
                                        )}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </Grid>
                    }
                    </Grid>
                }
            </Box>
        </Box>
    )
}
